.container {
  display: flex;
  align-items: flex-start;
  gap: 1.1em;
  margin-bottom: 1.1em;
  padding-bottom: 1.1em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  .image {
    height: 5.6em;
    width: 9.7em;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    border-radius: 1.8px;
    img {
      transition: all 0.3s ease-in-out 0s;
      width: 100%;
      object-fit: cover;
      object-position: center;
      /* overflow: hidden; */
      /* object-fit: fill; */
      height: 100%;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .descriptionContainer {
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    min-height: 5.45em;
  }

  h6 {
    font-size: 0.9em;
    font-weight: 500;
    font-family: "Poppins-Medium";
    color: #f3f3f3;
    margin-bottom: 0.5em;
    text-transform: capitalize;
    cursor: pointer;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0px;
    margin-bottom: 3px;
    width: 90%;
  }

  p {
    font-size: 0.8em;
    font-weight: 400;
    color: #f2f2f2;
    font-family: "Poppins-Medium";
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 1.35em;
  }

  .cardsFooter {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-size: 0.8em;
    gap: 1em;
    color: #c9c9c9;

    .cardFooterEelements {
      display: flex;
      align-items: center;
      gap: 0.4em;
      font-size: 0.9em;
      button {
        padding: 0px;
        background: transparent;
        border: none;
        cursor: pointer;
      }
    }
  }
}

.loading,
.loading2 {
  height: 5px;
  border-radius: 4px;
}
.loading2 {
  margin-top: 10px;
  width: 60%;
}

.waveLoader {
  position: relative;
  z-index: 12;
  background-color: #222421;
}

.growthContainer {
  position: absolute;
  top: 0;
  right: 0;
}
