.bgImage {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    border-radius: 10px;
    margin-bottom: 1rem;
    height: 12rem;
    transition: all .5s ease-in-out 0s;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    &:hover {
        img {
            transform: scale(1.2);
        }

    }

    img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all .5s ease-in-out 0s;

    }


}

.container {
    h2 {
        font-size: 1.2rem;
        color: var(--color-white);
        font-family: "Poppins-Medium";
        margin-bottom: 1.2rem;
        text-transform: capitalize;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        margin-bottom: .8rem;
    }

    p {
        color: #e1e1e1;
        font-size: .95rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        font-family: "Poppins-Medium";
    }
}