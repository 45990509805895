/* For React Component */
.widgetContainer {
  width: 100%;
  height: 100%;
  min-height: fit-content;
  margin: 2px 15px 0px 0px;
  max-width: 97vw;
  background-color: var(--widg_cont);
}

.tradingviewWidgetContainer,
.container_frame {
  width: 100%;
}

iframe {
  width: 100% !important;
}

.load-btn {
  background: #4c4ccc;
  padding: 0.5rem;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 30rem;
  z-index: 99999;
}



#embed-footer {
  display: none;
  height: 0px;
}

@media screen and (max-width: 790px) {
  .widgetContainer {
    border-radius: 7px;
  }
}
