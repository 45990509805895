.paymentTag {
  width: 280px;
  padding-top: 230px;
  position: relative;
  max-width: 100%;
  min-width: 280px;
  .pay0 {
    color: var(--background-color);
    background-color: var(--light-green);
    &::before {
      background-image: url("../../../../public/assets/images/tag/tag1.png");
    }
  }
  .pay1 {
    background-color: var(--color-white);
    &::before {
      background-image: url("../../../../public/assets/images/tag/tag2.png");
    }
  }
  .pay2 {
    background-color: var(--color-green);
    color: var(--color-black);
    &::before {
      background-image: url("../../../../public/assets/images/tag/tag3.png");
    }
  }
}
.paymentTagInner {
  width: 100%;
  border-radius: 20px;
  padding: 90px 20px 40px;
  position: relative;
  font-family: "Poppins-Medium";

  &::after {
    content: "";
    position: absolute;
    width: 36px;
    height: 38px;
    border-radius: 50%;
    background-color: var(--color-background-header);
    left: calc(50% - 18px);
    top: 20px;
  }
  &::before {
    content: "";
    position: absolute;
    width: 67px;
    height: 250px;
    top: -217px;
    left: calc(50% - 34px);
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.head {
  font-family: "Poppins-SemiBold";
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}
.subhead {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-bottom: 20px;
}
.price {
  font-size: 28px;
  font-weight: 500;
  line-height: 42px;
  text-align: left;
  position: relative;
  display: inline-block;
  &::after {
    content: "";
    position: absolute;
    left: 0px;
    top: 20px;
    width: 100%;
    height: 1.5px;
    background-color: var(--color-orange);
    transform: rotate(-13deg);
  }
}
.dis {
  font-family: Poppins;
  font-size: 54px;
  font-weight: 500;
  line-height: 81px;
  text-align: left;
}
.disDet {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--color-orange);
  margin: 30px 0px 40px;
  opacity: 0;
}
.btnInner {
  background-color: var(--background-color);
  font-family: Poppins-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--color-white);
  border-radius: 30px;
  height: 46px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payNow {
  width: 100%;
}
@media only screen and (max-width: 650px) {
  .head {
    font-size: 20px;
  }
  .subhead {
    font-size: 15px;
  }
  .price {
    font-size: 25px;
  }
  .dis {
    font-size: 45px;
    line-height: 70px;
  }
}
@media only screen and (max-width:370px){
  .paymentTag{
    min-width: 240px;
  }
}