


@mixin responsive-font-size() {
 
    font-size: 100%; // base 16px
    // font-size: 62.5%; // base 10px

    @media screen and (max-width: 3900px) {
      font-size: 225%;
    }
    @media screen and (max-width: 2560px) {
      font-size: 133%;
    }
    @media screen and (max-width: 2000px) {
      font-size: 100%;
    }
    @media screen and (max-width: 1550px) {
      font-size: 95%;
    }
    @media screen and (max-width: 1370px) {
      font-size: 92%;
    }
    @media screen and (max-width: 1289px) {
      font-size: 79%;
    }
    @media screen and (max-width: 1100px) {
      font-size: 90%;
    }
    @media screen and (max-width: 790px) {
      font-size: 81%;
    }
    @media screen and (max-width: 500px) {
      font-size: 76%;
    }
    @media screen and (max-width: 410px) {
      font-size: 72%;
    }
  }
  