:root {
  --background-color: rgba(31, 69, 79, 1);
  --background-color2: rgba(22, 49, 56, 1);
  --background-color3: rgba(44, 68, 77, 1);
  --background-color4: rgba(21, 37, 42, 1);
  --background-light-color: rgb(22 113 125);

  --dark-color: rgba(16, 17, 15, 1);
  --dark-color2: rgba(21, 37, 42, 1);
  --dark-color3: rgba(72, 73, 76, 1);
  --btn-color: rgba(31, 69, 79, 1);
  --dark-gray-background: rgba(42, 43, 47, 1);
  --gray-color: rgba(43, 49, 57, 1);
  --light-green: rgba(174, 229, 209, 1);
  --background-gray2: rgba(86, 83, 83, 1);
  --background-light-gray: rgba(217, 217, 217, 1);
  --background-dark-blue: rgba(40, 47, 106, 1);
  --color-background-header: rgba(30, 30, 36, 1);
  --global-dark-background: rgba(30, 47, 53, 1);
  --global-dark-background: rgba(21, 37, 42, 1);
  --color-offwhite2: rgba(198, 198, 198, 1);

  --color-black: rgb(0, 0, 0);
  --color-white: rgba(255, 255, 255, 1);
  --color-light-white2: rgba(250, 250, 250, 1);
  --color-offwhite: rgba(234, 236, 239, 1);
  --color-light-white: rgba(244, 244, 244, 1);
  --color-disabled: rgba(143, 144, 145, 1);
  --color-green: rgba(204, 253, 81, 1);
  --color-orange: rgba(234, 89, 84, 1);
  --color-orange2: rgba(240, 110, 34, 1);
  --color-pink: rgba(206, 44, 138, 1);
  --color-red:rgba(169, 39, 39, 1);
  --color-light-gray: rgba(220, 220, 220, 1);
  --color-btn-green: rgba(4, 214, 117, 1);
  --color-btn-green-dark: rgb(0, 175, 93);
  --color-dark-green: rgba(98, 167, 74, 1);
  --color-gray-font: rgba(180, 180, 180, 1);
  --color-input-placeholder: rgba(113, 113, 113, 1);
  --color-light-border: rgba(54, 57, 60, 1);
  --otp-gray: #3a3a3a;
  --otp-input: rgba(33, 33, 33, 1);
  --color-tag-background: rgba(166, 166, 166, 1);
  --color-offwhite3: rgba(235, 235, 235, 1);
  --color-font-gray: rgba(121, 121, 121, 1);
  --color-light-black: rgba(40, 40, 40, 1);
  --fb-background: rgba(77, 96, 158, 1);
  --light-gray: rgba(19, 19, 19, 1);
  --border-gray: rgba(50, 50, 50, 1);
  --color-splitter: rgba(139, 139, 139, 1);
  --color-green2: rgba(141, 236, 44, 1);
  --color-blue: rgba(0, 140, 255, 1);
  --color-dark-gray: rgba(120, 115, 115, 1);
  --color-light-gray-small: rgba(204, 204, 204, 1);
  --color-qr-gray: rgba(84, 83, 83, 1);
  --color-purple: rgba(93, 77, 130, 1);
  --color-green3: rgba(127, 190, 67, 1);
  --color-violet: rgba(56, 20, 166, 1);
  --color-purple2: rgba(139, 103, 169, 1);
  --color-pink2: rgba(186, 72, 138, 1);
  --color-news-gray: rgba(110, 110, 115, 1);
  --color-news-gray-bg: rgba(32, 32, 32, 1);
  --color-news-gray-bg2: rgba(48, 48, 48, 1);
  --color-percent-green: rgba(7, 248, 181, 1);
  --color-percent-red: rgba(255, 83, 97, 1);
  --tab-background: rgba(20, 31, 35, 1);
  --light-white-color: rgba(255, 255, 255, 0.3);
  --color-yellow: rgba(255, 215, 71, 1);
  --color-dark-blue-font: rgba(49, 89, 95, 1);
  --color-option: rgba(38, 40, 50, 1);
  --text-gray: rgba(238, 238, 238, 1);
  --soft-gray: rgba(164, 154, 154, 1);
  --light-gray: rgba(235, 233, 233, 1);
  --neo-lime: rgba(204, 253, 80, 1);
  --medium-gray: rgba(128, 128, 128, 1);
  --dark-gray: rgba(65, 64, 69, 1);
  --mist-blue: rgba(5, 79, 190, 1);
  --leaf-green: rgba(5, 190, 122, 1);
  --steel-gray: rgba(170, 170, 170, 1);
  --leaf-green: rgba(0, 193, 31, 1);
  --indices-yellow: rgb(255, 214, 0);
  --light-blue: rgba(182, 255, 229, 1);
  --commodity-pink: #c94289;
  --stock-blue: #4bbccc;
  --off-white: rgb(226 239 255);
  --navy-blue: #30569f;
  --deep-blue: rgb(1 16 44);
  --deep-violet: rgb(34 20 74);
  --coffee-brown: rgba(47, 46, 50, 1);
  --commodity-blue: rgba(8, 19, 56, 1);
  --loss-pink: rgba(254, 101, 167, 1);
  --mid-dark: rgba(16, 17, 15, 1);
  --neo-lime-dark: rgba(204, 252, 80, 1);
  --fluro-green: rgba(173, 255, 66, 1);
  --neo-lime-dark: rgba(204, 252, 80, 1);
  --card-bg: rgba(2, 32, 41, 1);
}
