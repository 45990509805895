.container{
    min-width: 48px;
    max-width: 48px;
    background: var(--neo-lime);
    font-size: 11px;
    color: var(--color-black);
    font-family: "Poppins-Medium";
    border-radius: 13px;
    text-align: center;
    line-height: 13px;
    padding: 4px 10px;
}