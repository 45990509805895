.highlightWrapper {
  padding: 1em;
  padding-top: 0px;
  margin-bottom: 10px;

  .toggleWrapper {
    display: flex;
    justify-content: flex-end;
    // margin: 1em 0;
    padding: 0 0 1em 0;

    .toggle {
      display: flex;
      align-items: center;
      gap: 1em;

      input {
        width: 2.8em;
        padding: 0.6em;
      }

      span {
        color: #000;
        font-family: Poppins;
        font-size: 1.1em;
        font-style: normal;
        font-family: "Poppins-SemiBold";
        font-weight: 600;
      }
      .spotHighlightLabel {
        color: #fff;
      }
    }
  }

  .highlightCardsWrapper {
    display: flex;
    justify-content: space-between;
    gap: 2em;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */

    .highlightCard {
      display: flex;
      flex-direction: column;

      border-radius: 9.52355px;
      padding: 20px 25px;
      padding-bottom: 10px;

      .title {
        font-size: 1.15em;
        font-weight: 700;
        font-family: "Poppins-SemiBold";
        color: white;
        margin-bottom: 0.7em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img.book{
          margin-right: 7px;
        }
      }

      .eachValue {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.6em;

        .name {
          font-size: 0.8em;
          color: #ffffff;
          font-weight: 600;
          font-family: "Poppins-SemiBold";
        }

        .value {
          font-size: 0.9em;
          font-family: "Poppins-SemiBold";
          color: white;
        }
      }
      &.topCommunityPost {
        width: 32%;
        padding: 1em;
        .eachValue {
          justify-content: center;
        }
        .tenxComment {
          .w20 {
            flex-shrink: 0;
            width: 2.3em;
            img {
              width: 100%;
            }
          }
          &Head {
            display: flex;
            align-items: center;
            gap: 1.5em;
            margin-bottom: 0.9em;
            img {
              max-width: 25px;
              margin: 0 0.5em;
            }
            p {
              color: #fff;
              font-family: "Poppins-SemiBold";
              font-size: 1.25em;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              margin: 0;
            }
          }
          &Content {
            display: flex;
            gap: 1.5em;
          }
          .tenxCommentImage {
            width: 2.5em;
            height: 2.5em;
            border-radius: 50%;
          }
          &Details {
            margin: 0.3em 0;
            .proName {
              display: flex;
              align-items: center;
              gap: 1em;
              img {
                max-width: 20px;
              }
              p {
                color: #fff;
                font-family: "Poppins-SemiBold";
                font-size: 0.8em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0;
                text-transform: capitalize;
              }
            }
          }
          &Data {
            color: #fff;
            // font-family: "Poppins-Medium";
            font-size: 0.8em;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin: 0.7em 0;
            height: 3em;
            word-break: break-word;
          }
          &Stats {
            display: flex;
            align-items: center;
            gap: 1em;
          }
          &Item {
            display: flex;
            align-items: center;
            gap: 0.4em;
            color: #fff;
            font-family: "Poppins-Medium";
            font-size: 0.85em;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0;
            position: relative;
            img {
              max-width: 1.2em;
            }
            p {
              margin: 0;
            }
          }
          .seperator {
            height: 3px;
            width: 3px;
            border-radius: 50%;
            background: #fff;
          }
        }
      }

      &.infoCard {
        width: 33%;
        // background: linear-gradient(145.36deg, #5377f9 1.2%, #2ebf91 93.33%);
        background-color: var(--card-bg);
      }

      &.topGainerCard {
        width: 33%;
        // background: linear-gradient(145.36deg, #e246ff 1.2%, #1b4bc7 93.33%);
        background-color: var(--card-bg);
        .title {
          display: flex;
          align-items: center;
          gap: 1em;

          .fire {
            width: 1.5em !important;
            img {
              width: 100%;
            }
          }
        }

        .name {
          .icon {
            width: 0.9em;
            margin: 0 0.4em;
          }
        }

        .value {
          display: flex;
          gap: 0.5em;
          align-items: center;

          .arrow {
            width: 0.8em;
            img {
              width: 100%;
            }
          }
        }
      }

      &.topCommunityPost {
        // background: linear-gradient(146.38deg, #b82eb4 1.14%, #f27121 116.77%);
        background-color: var(--card-bg);
        // display: flex;
        // align-items: center;
        // justify-content: center;
        color: white;

        .no-data {
          display: flex;
          justify-content: center;
          padding: 1em 0;
          font-size: 0.9em;
        }
      }
    }
  }
}
.highlightCardsWrapper::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 500px) {
  .highlightCardsWrapper {
    justify-content: flex-start !important;
    flex-wrap: nowrap;
    padding: 1em 0em;
    max-width: 100vw;
    overflow: auto;
    gap: 1em;

    // padding: 1em;
    .highlightCard {
      border-radius: 7px !important;
    }

    .eachValue {
      margin-bottom: 1em !important;
    }

    .title {
      margin-bottom: 0.5em !important;
    }
  }
}
// @media screen and (min-width: 790px) and (max-width: 890px) {
//   .eachValue{
//     width: 13em !important;
//   }
// }

@media screen and (max-width: 750px) {
  .highlightWrapper {
    padding: 1.5em 0.5em;

    gap: 1em;
    .toggleWrapper {
      padding: 0.5em;
    }

    .highlightCardsWrapper {
      .highlightCard {
        &.info-card {
          .eachValue {
            width: 60vw;
          }
        }
        &.top-gainer-card {
          .eachValue {
            width: 60vw;
          }
        }
        &.topCommunityPost {
          width: 60vw;
          .eachValue,
          .tenx-comment {
            // width: 60vw;
            justify-content: center;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .highlightWrapper {
    padding: 1.5em 0em;

    gap: 1em;
    .toggleWrapper {
      padding: 0.5em;
    }

    .highlightCardsWrapper {
      gap: 1em !important;
      justify-content: flex-start !important;
      flex-wrap: nowrap;
      // max-width: 200vw;
      overflow: auto;

      .highlightCard {
        // padding: 1em;
        width: 100% !important;
        .title {
          margin: 0.2em 0;
          // text-align: center;
          font-family: "Poppins-Bold";
        }

        .eachValue {
          margin-bottom: 0.5em;
          display: flex;

          .name {
            font-size: 1.1em;
            font-weight: 400;
          }

          .value {
            font-size: 1.1em;
            font-weight: 400;
          }
        }

        &.info-card {
          .eachValue {
            width: 20em;
          }
        }
        &.topGainerCard {
          .eachValue {
            width: 20em;
          }
        }
        &.topCommunityPost {
          min-width: 28em;
          max-width: 28em;
          display: flex;
          // justify-content: center;
          .eachValue,
          .tenx-comment {
            // width: 20em;
            justify-content: center;
          }

          .tenxComment {
            &Content {
              display: flex;
              gap: 1.5em;
            }
            &Imge {
              width: 2.5em;
              height: 2.5em;
            }
            &Details {
              margin: 0.3em 0;
              .proName {
                img {
                  max-width: 20px;
                }
                p {
                  font-size: 1.1em;
                }
              }
            }
            &Head {
              margin-bottom: 1.5rem;
            }
            &Data {
              font-size: 1.1em;
              height: 3.2em;
            }
            &Item {
              font-size: 1em;
              img {
                max-width: 1.2em;
              }
              p {
                margin: 0;
              }
            }
          }
        }
      }

      /* width */
      &::-webkit-scrollbar {
        // width: 2px;
        opacity: 0;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 15px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
      }
    }
  }
}

@media screen and (max-width: 830px) {
  .highlightWrapper {
    .highlightCardsWrapper {
      .highlightCard {
        .eachValue {
          width: 20em;
        }
      }
    }
  }
}

.swiperPaginationBulletActive {
  background: var(--color-green) !important;
}

.swiperPaginationBullet {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: rgba(191, 191, 191, 1);
}

.col12 {
  height: 100%;
  > div {
    height: 100% !important;
  }
  .swiper-pagination {
    bottom: 10px;
  }
}

.swiperMod {
  bottom: 10px;
}
.toggle {
  display: block;
  min-height: 1.5em;
  padding-left: 1.5em;
  margin-bottom: 0.125em;
}

.formCheck {
  display: block;
  // min-height: 1.5em;
  padding-left: 1.5em;
  margin-bottom: 0.125em;
}

.toggle input {
  width: 2.8em;
  padding: 0.6em;
}
