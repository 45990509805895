.commentContainer {
  padding-bottom: 35px;
  margin-bottom: 34px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  .commentHead {
    display: flex;
    margin: 0;
    align-items: center;
    gap: 0.6rem;
    width: 100%;
    justify-content: space-between;

    .mainHead {
      display: flex;
      align-items: center;
    }

    .commentOtherInfo {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        left: -39px;
        top: 0;
        background: rgba(255, 255, 255, 0.1);
      }
    }

    .profile {
      padding-right: 20px;
      position: relative;
      z-index: 11;

      img {
        padding: 0;
        border-radius: 50%;
        height: 38px;
        width: 38px;
        object-fit: cover;
        object-position: center center;
        vertical-align: middle;
        min-width: 39px;
        min-height: 39px;
      }
    }

    .userName {
      font-size: 1.1rem;
      color: var(--color-white);
      text-transform: capitalize;
      font-family: "Poppins-SemiBold";
    }

    .date {
      padding: 0 1.2rem;
      font-size: 0.73em;
      color: #a3a6afd1;
      white-space: nowrap;
    }
  }

  .likes {
    display: flex;
    align-items: center;
    gap: 1rem;

    img {
      width: 1.25rem;
      vertical-align: middle;
    }

    > div {
      display: flex;
      align-items: center;
      gap: 0.6rem;

      span {
        color: #fff;
        font-size: 1em;
      }
    }
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgba(220, 220, 220, 0.8);
    max-height: 4rem;
    height: auto;
    margin: 0px;
  }

  .replyComment {
    font-size: 1.1rem !important;
    color: rgba(220, 220, 220, 0.8);
    line-height: 1.6;
    word-break: break-word;
    font-family: "Poppins-Medium";
  }

  .replyComment {
    li,
    h3,
    h1,
    h2,
    h4,
    h5,
    h6,
    p,
    span {
      font-size: 1rem !important;
    }
  }

  .repliesList {
    display: flex;
    align-items: center;
    > p {
      margin-right: 21px;
    }

    .replyText {
      text-decoration: underline;
      color: #fff;
      font-size: 0.9rem;
    }

    .viewReplyBtn {
      background: #000;
      // border: 0.2rem solid #bbb;
      border-radius: 5px;
      padding: 0rem 0.2rem;
      margin: 0.5rem;
      cursor: pointer;
      padding: 0px;
      border: none;

      img {
        vertical-align: middle;
      }

      &:hover {
        opacity: 0.8;
      }

      &:disabled {
        opacity: 0.5;
      }

      .rotate180Deg {
        transform: rotate(180deg);
      }
    }

    .replyCommentLabel {
      color: rgba(161, 161, 156, 1);
      font-size: 0.75em;
      margin-left: 21px;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }

  .readMore {
    cursor: pointer;
    padding: 0.5rem;
    font-size: 0.76rem;
    color: #b0fd33;
    display: flex;
    margin: 0 5rem;
    flex-flow: row-reverse;

    &:hover {
      opacity: 0.5;
    }
  }

  .seeMore {
    cursor: pointer;
    overflow-y: auto;
    -webkit-line-clamp: initial;
    max-height: 12rem;
  }
}

.mobileThumbs {
  display: flex;

  .like-unlike {
    padding-right: 0.4rem;

    img {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}

@media screen and (max-width: 790px) {
  .userName {
    color: #dcdcdc;
    text-align: center;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 600;
    font-family: "Poppins-SemiBold";
    line-height: 40.147px;
  }

  .date {
    padding: 0 0.85rem;
  }

  .description {
    margin: 0 0 0 5.1rem;
    color: rgba(220, 220, 220, 0.8);
    font-size: 13.382px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    transition: all 0.5s;
  }

  .repliesList {
    margin: 0.5rem;
    align-items: center;
    justify-content: space-around;

    .replyCommentLabel {
      align-self: auto;
    }
  }

  .replyText {
    padding-right: 0.5rem;
  }
}

.repliesMessages {
  margin-left: 7rem;
}

.replieInp {
  margin-left: 7rem;
}
