.progressBarContainer {
    width: 2.5px;
    height: 6em;
    background-color: #2d2c2f;
    min-height: 0;
    position: relative;
}




.progressBar {
    width: 100%;
    background-color: var(--neo-lime) !important;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    transition: all 0.2s
}

.progressDot {
    width: 6px;
    height: 6px;
    position: absolute;
    top: 0;
    left: 50%;
    background: var(--neo-lime);
    border-radius: 50%;
    z-index: 10;
    transform: translate(-50%);
    transition: all 0.2s
}