.postComment {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.cancel_btn {
  background: #000;
  color: #fff;
  padding: 0.5rem 1.3rem;
  border-radius: 3.5rem;
  margin: 0.5rem;

  &:hover {
    opacity: 0.8;
  }
}

.post_btn {
  border: none;
  background: #ccfd50;
  padding: 0.6em 2.2em;
  border-radius: 3.5em;
  margin: 0.6em;
  margin-top: 30px;
  color: #000;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.9em;
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  .sendImg {
    width: 1.5em;
    margin-left: 0.9em;
  }
}

.userNameValidation {
  justify-content: flex-end;
  display: flex;
  text-align: center;
  color: red;
  font-size: 0.9rem;

  .user_name {
    padding: 0 0.2rem;
  }
}

.commentsContainer {
  padding-top: 35px;
  .addComments {
    .profileImg {
      height: 40px;
      width: 40px;
      margin-right: 10px;

      .profile {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .commentBoxContainer {
      width: 100%;
      display: flex;
      position: relative;
      align-items: center;
      border: 1px solid rgba(92, 92, 92, 0.7);
      border-top: none;
      border-left: none;
      border-right: none;

      .commentsBox {
        width: 85%;
        margin: 0%;
        background-color: transparent;
        padding: 0.8em;
        padding-left: 0px;
        border: none;
        color: white;
        // height: 4.5rem;
        outline: none;
        min-height: 60px;
        /* Show scrollbar when content overflows */
        resize: vertical;

        &:focus {
          outline: none;
        }
      }

      [contentEditable="true"]:empty:not(:focus):before {
        content: attr(data-text);
      }

      .send {
        position: absolute;
        top: 11px;
        height: 20px;
        right: 45px;
      }

      .imgSelectWrap {
        position: absolute;
        top: 10px;
        height: 20px;
        right: 50px;
        width: auto;
        input {
          width: 100%;
          height: 100%;
          position: absolute;
          opacity: 0;
        }
      }
      .smiley {
        position: absolute;
        top: 11px;
        height: 20px;
        right: 15px;
        width: auto;
      }

      .sendIcon {
        position: absolute;
        top: 11px;
        height: 20px;
        right: 45px;
        width: auto;
      }
    }

    .commentBoxContainer:after {
      content: "";
      height: 1.5px;
      width: 0px;
      position: absolute;
      bottom: -1px;
      background: transparent;
      transition: width 0.5s ease, background-color 0.5s ease;
    }

    .commentBoxContainer:focus-within:after {
      left: 0;
      background: #fff;
      animation: increaseWidth 0.5s ease forwards;
    }

    @keyframes increaseWidth {
      0% {
        width: 0;
        left: 50%;
      }

      100% {
        width: 100%;
        left: 0;
      }
    }
  }

  .emojiContainer {
    display: block;
    cursor: pointer;
    position: relative;
    top: -15px;
    z-index: 9;
    width: 85%;
  }

  .commentsFullview {
    right: 0em !important;
  }

  .emojiMart {
    cursor: pointer;
    position: absolute;
  }

  textarea {
    resize: none;
  }

  .comments {
    .load-more {
      font-weight: 600;
      padding: 1em;
    }

    .disableLoadMore {
      color: var(--tf_fontColor) 00087;
    }

    ul {
      list-style: none;
      padding: 0em;

      li {
        display: flex;
        padding: 1em;

        .profile {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .commentWrapper {
          width: auto;
          padding: 1em;
          font-size: 0.9em;
          display: flex;
          flex-direction: column;
          background-color: #ffffff4d;
          border-radius: 5px;
          // padding-top: 13px;
          // padding-bottom: 1px;

          .commenterName {
            font-weight: 650;
            // margin-bottom: 7px;
          }

          .commentText {
            font-weight: 600;
            padding-top: 0.5em;
            display: flex;
            flex-direction: column;

            .created_at {
              font-weight: 600;
              font-size: 9px;
            }
          }
        }
      }
    }
  }

  // .noDataFound-comm {
  // height: 200px;

  // .nodata-container {
  //     min-height: 6em !important;
  //     height: 6em !important;
  // }
  // }
}

// ::placeholder{
//     color: white !important;
// }
.dis_hide {
  display: none;
}

.popupOuter {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;

  &,
  .popupOverlay {
    position: relative;
  }

  .popupOverlay {
    z-index: 1;
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    // background: rgba(0,0,0,.4);
    // backdrop-filter: blur(2px);
  }

  .popupContent {
    position: relative;
    z-index: 2;
    display: block;
  }
}

.commentSection {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 95%;
}
.imageContainer {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  .imgWrap {
    width: 5rem;
    position: relative;
    padding: 0.5rem;
    border: 1px solid #fff;
    border-radius: 10px;
    img {
      width: 100%;
    }
    .closeIcon {
      position: absolute;
      padding: 5px;
      border: 2px solid #fff;
      border-radius: 50%;
      transform: translate(50%, -60%);
      right: 0;
      background: #000;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
}

textarea::placeholder {
  color: #a8a8a8;
  font-size: 1.2rem;
  margin-bottom: 1.4rem;
}

@media screen and (max-width: 750px) {
  .postComment {
    justify-content: flex-start;
  }

  .commentsBox {
    width: 79% !important;
  }

  .dis_hide {
    display: block !important;
  }

  .commentsContainer {
    .emojiContainer {
      display: block;
      position: relative;
      top: 0px;
      right: 0em;
      z-index: 99;
      overflow-x: hidden;
    }
  }
}
