.chartDetail {
  &__header {
    display: flex;
    padding-top: 1rem;
    margin-bottom: 10px;
    h4 {
      font-size: 1.5em;
      color: var(--color-white);
      font-family: "Poppins-Medium";
      line-height: 1.2;
      @media screen and (max-width: 820px) {
        font-size: 1.65em;
        font-style: normal;
        line-height: normal;
        margin-bottom: 20px;
      }

      margin: 0;
    }
  }

  &__position,
  &__price {
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
    margin: 0 1rem;
    border-radius: 0.2rem;

    &.short,
    &.long {
      display: flex;
      align-items: center;
      padding: 0.5rem;
    }

    .long {
      display: flex;
      max-width: 6rem;
      align-items: center;
      color: #fff;
      background: #009860;
      text-align: center;
      border-radius: 3.799px;
      padding: 0.25rem 1rem;
      line-height: 1.35rem;

      i {
        display: inline-block;
        margin-right: 0.3rem;
        position: relative;
        left: -3px;
        top: 4px;
      }
    }

    .short {
      display: flex;
      max-width: 6rem;
      align-items: center;
      margin-right: 0.3rem;
      color: #fff;
      background: #ea5954 !important;
      text-align: center;
      border-radius: 3.799px;
      padding: 0.25rem 1rem;
      line-height: 1.35rem;

      i {
        display: inline-block;
        margin-right: 0.4rem;
        position: relative;
        top: -0.1em;
      }
    }
  }

  &__coinpair {
    font-size: 0.8em;
    @media screen and (max-width: 820px) {
      font-size: 14px;
      > span {
        max-width: unset !important;
      }
    }
    font-weight: 400;
    color: #eaeaea;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    > span {
      max-width: 11rem;
    }
  }

  &__keywords {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
    margin: 1rem 0;
    width: 100%;
    max-height: 6rem;
  }

  &__chips {
    cursor: pointer;
    background: #3b3a3d;
    padding: 0.5rem;
    border-radius: 1.5rem;
    margin: 0.5rem;
    font-size: 0.6rem;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10rem;
  }

  &__desc {
    h4 {
      font-size: 1.5em;
      font-weight: 600;
      line-height: 2rem;
      color: rgb(247, 247, 247);
      line-height: 1.2;
      margin: 0px;
      font-family: "Poppins-Medium";
      @media screen and (max-width: 820px) {
        font-size: 1.72em;
      }
    }

    p,
    span {
      font-family: "Poppins-Medium";
      font-weight: 400;
      font-size: 0.9em;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      color: #d5d5d5;
      margin-top: 1.5rem;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      color: rgba(220, 220, 220, 0.8);
      max-height: 7.5rem;
      height: auto;
      word-break: break-all;
      max-width: unset;
      margin-bottom: 1.1rem;
      @media screen and (max-width: 820px) {
        color: #d4d4d4;
        font-size: 13.298px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-height: 8em;
      }
      &.seeMore {
        // height: 12.5rem;
        cursor: pointer;
        overflow-y: auto;
        -webkit-line-clamp: initial;
        max-height: 15rem;
        height: auto;
      }
    }
    span {
      margin: 0px;
    }
  }

  .line_clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__readMore {
    cursor: pointer;
    // padding: 0.5rem;
    color: #fff;
  }
}

.line_clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (max-width: 820px) {
  .chart-detail {
    &__header {
      padding-top: 1rem;
      flex-direction: column;

      h4 {
        font-size: 1.5em;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    &__coinpair {
      span {
        font-size: 14px;
      }
    }

    &__keywords {
      flex-direction: row;
      margin: 1rem 0;
      width: 100%;
      max-height: max-content;
      overflow: inherit;
    }
    &__keywords::-webkit-scrollbar {
      display: none;
    }

    &__position {
      padding: 0.25rem 0rem;
      font-size: 0.8rem;
      margin: 1rem 0rem;
      border-radius: 0.2rem;
    }

    &__chips {
      padding: 0.5rem 1rem;
      color: #c9c9c9;
      font-size: 15.197px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      min-width: 6rem;
    }

    &__desc {
      margin: 1.5rem auto;

      h4 {
        color: #f6f6f6;
        font-size: 1.72rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      p,
      span {
        color: #d4d4d4;
        font-size: 13.298px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-height: 8rem;
      }
    }
  }

  .chart-detail {
    &__price {
      padding: 1rem 0;
    }
  }

  .line_clamp {
    -webkit-line-clamp: initial;
  }
}

.desktopHr {
  margin: 1rem 0;
  color: var(--color-white);
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
  @media (max-width: 790px) {
    display: none;
  }
}
