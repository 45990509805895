.button {
  background-color: var(--btn-color);
  box-shadow: none;
  border: 0px;
  cursor: pointer;
  border-radius: 30px;
  font-size: 1.2rem;
  transition-duration: 200ms;
  padding: 0px;
  color: var(--color-black);
  &.full {
    width: 100%;
  }
  &.light {
    background-color: var(--light-green);
  }
  &.black {
    color: var(--color-white);
    background-color: var(--color-black);
  }
  &.neon {
    background-color: var(--color-green);
    color: var(--color-black);
  }
  &.danger {
    background-color: var(--color-orange);
    color: var(--color-black);
  }
  &.bordered {
    border: 1px solid var(--neo-lime);
    background: transparent;
  }
  &.materialDanger {
    background-color: var(--color-orange);
    color: var(--color-white);
    font-family: "Poppins-Medium";
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2.584rem;
    border-radius: 5px;
    padding: 4px 70px;
  }
  &.icon {
    background-color: transparent;
    box-shadow: none !important;
  }
  &:hover {
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.5);
  }
  &:active {
    box-shadow: 2px 2px 7px 1px rgba(0, 0, 0, 0.3) inset;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
    &:hover {
      box-shadow: none;
    }
  }
}
button {
  transition-duration: 200ms;
  &:hover {
    transform: scale(1.04);
  }
  &:disabled {
    &:hover {
      transform: scale(1);
    }
  }
  &.loading {
    display: flex;
    align-items: center;
  }
}

.spinner {
  margin-left: 10px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
