.tabView{
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  li{
      padding: 1em;
      font-size: 1em;
      color: #A5A5A6;
      cursor: pointer;
      font-family: "Poppins";
      &:nth-child(1){
        padding-left: 0px;
      }
      
  }
  .selected{
      color: var(--color-white);
      border: 2px solid #ccfd50;
      border-top: none;
      border-right: none;
      border-left: none;
  }
}