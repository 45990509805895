.watchCard {
    position: relative;
    overflow: hidden;
    width: 24rem;
    height: 17rem;
    margin: 0px 1.4rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 1.1rem;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    &::after {
        content: "";
        -moz-box-shadow: inset 0 -150px 134px -170px #000;
        -webkit-box-shadow: inset 0 -150px 134px -170px #000;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 56.3%, rgba(0, 0, 0, .61) 100%, #000000 51%);
        padding: 5em;
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .name {
        font-size: 1.2rem;
        color: var(--color-white);
        text-transform: uppercase;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: 10;
        font-family: "Poppins-Medium";
    }
}