.guageLabel {
  font-family: "Poppins-Medium";
  font-size: 8px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  position: absolute;
}

.guageWrapper {
  position: relative;
  min-height: 183px;
}

.bz {
  color: var(--neo-lime);
  bottom: 66%;
  transform: rotate(-48deg);
  left: 21%;
}

.hodel {
  color: #fff;
  bottom: 87%;
  left: 48%;
  transform: rotate(0deg);
  transform-origin: center;
}

.tp {
  color: var(--color-orange2);
  bottom: 63%;
  right: 21%;
  transform: rotate(47deg);
  transform-origin: center;
}

.dangerRed {
  color: rgba(212, 23, 31, 1);
  bottom: 20%;
  right: 10%;
  transform: rotate(90deg);
}
.countInYear {
  font-family: "Poppins-SemiBold";
  font-size: 3.2rem; // 48px / 12px
  font-weight: 600;
  line-height: 3.311rem; // 39.73px / 12px
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--neo-lime);
  margin-top: 0px;
  height: 120px;
  .countYearLabel {
    font-family: "Poppins-Medium";
    font-size: 1.194rem; // 14.33px / 12px
    font-weight: 500;
    line-height: 1.332rem; // 15.98px / 12px
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--neo-lime);
  }
}