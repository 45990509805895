.sideStickyGroup {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    position: sticky;
    top: 20vh;
    transition: all 0.125s ease 0s;
      
  h5 {
    color: #dfdada;
    font-size: 0.8em;
    margin-top: 0.4em;
    margin-bottom: 0px;
  }
  }
  
  .action {
    text-align: center;
    position: relative;
    .item {
      width: 1.2em;
      button{
        padding: 0px;
        border: none;
        background: transparent;
        cursor: pointer;
      }
      img{
        width: 100%;
        vertical-align: middle;
      }
    }
    h5 {
      color: #dfdada;
      font-size: 0.8em;
      margin-top: 0.4em;
    }
    .bookmark {
      width: 0.9em;
      &.large {
        width: 1.2em;
      }
    }
  }

  
  .bookmark {
    width: 0.9em;
  }
  
  .bookmark.large {
    width: 1.2em;
  }
  
  .progressBarContainer {
    position: relative;
    width: 100%;
  }
  
  .progressBar {
    width: 100%;
    height: 5px;
    background-color: lightgray;
  }
  
  .progressDot {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: blue;
    border-radius: 50%;
    transition: top 0.2s ease;
  }
  
  .loader {
    width: 1.2em;
    height: 1.2em;
    background-color: lightgray;
  }
  