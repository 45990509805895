/*
Bullet style pagination
*/

.swiperPaginationBulletActive {
  background: var(--color-green) !important;
}

.swiperPaginationBullet {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(191, 191, 191, 1);
}

/*
  Remove left right arrows
  */

.noNavArrows {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}
