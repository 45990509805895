.smartcard_filters {
  --scrollbar-thumb: transparent;
  display: flex;
  gap: 1em;
  margin-bottom: 2em;
  padding: 1em;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  // max-width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
  // margin-bottom: 0em;
  // margin-left: 3em;
  // margin-left: 5%;
  .filter_btn {
    border: none;
    display: grid;
    place-content: center;
    height: 3.5em;
    // width: 13.9%;
    font-family: "Poppins-SemiBold";
    border-radius: 0.7em;
    padding: 0.5em;
    color: white;
    background-color: #212023;
    font-size: 1em;
    font-weight: 800;
    width: calc(100%/6 - 1em);;
    cursor: pointer;
    &.future {
      color: #000000;
      background: var(--neo-lime);
      border: var(--neo-lime);

      &.active {
        border: 2px solid var(--color-white);
      }
    }

    &.spot {
      color: white;
      background: var(--coffee-brown);

      &.active {
        border: 2px solid var(--neo-lime);
      }
    }

    &.forex {
      color: black;
      background: white;

      &.active {
        border: 2px solid var(--neo-lime);
      }
    }

    &.stock {
      color: var(--color-black);
      background: var(--stock-blue);

      &.active {
        border: 2px solid #ffffff;
      }
    }

    &.indicies {
      color: black;
      background: var(--indices-yellow);

      &.active {
        border: 2px solid #ffffff;
      }
    }

    &.commodit {
      color: white;
      background: var(--commodity-blue);

      &.active {
        border: 2px solid #ffffff;
      }
    }

    //routerlink active class
  }
}

.smartcard_filters::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 750px) {
  .smartcard_filters {
    justify-content: initial;
    overflow: auto;
    // padding: 0 1em;
    gap: 0.2em;
    padding: 0;
    margin-bottom: 0;
    &:first-child {
      padding-left: 1em;
    }
    .filter_btn {
      font-size: 1.25em;
      padding: 1.5rem 3rem;
      margin: 0px 3px 10px 1px;
      min-height: 65px;
      min-width: 220px !important;
      width: auto;
      height: auto;
      border-radius: 0.35em;

      &.commodit {
        min-width: 10em;
      }
    }

    /* width */
    &::-webkit-scrollbar {
      width: 2px;
      opacity: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 15px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }
}
@media screen and (max-width: 830px) {
  .smartcard_filters {
    gap: 1em;
    padding: 1em 0px;
    // padding-left: 5em;
    .filter_btn {
      min-width: 6em;
    }
  }
}
