.banner {
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  padding: 1rem;
  margin: 0rem;
  cursor: pointer;
  margin-top: 0px;
  position: relative;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    transform: scale(1);
    border-radius: 10px;
    position: absolute;
    object-fit: cover;
    transition: all 0.5s ease-in-out 0s;
  }

  .title {
    font-size: 1.7rem;
    color: var(--color-white);
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
    margin-bottom: 20px;
    z-index: 10;
    font-family: "Poppins-Medium";
  }

  .subTitle {
    font-size: 1rem;
    color: var(--color-white);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 10;
    font-family: "Poppins-Medium";
  }
}

.marq2Desktop {
  display: none;
}

@media (min-width: 768px) {
  .marq2Mob {
    display: none;
  }
  .marq2Desktop {
    display: flex;
  }
}

@media screen and (max-width: 820px) {
  .banner {
    justify-content: flex-end;
  }

  .banner::after {
    content: "";
    -moz-box-shadow: inset 0 -150px 134px -170px #000;
    -webkit-box-shadow: inset 0 -150px 134px -170px #000;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.61) 100%,
      #000000 51%
    );
    padding: 5em;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.marq1 {
  padding: 2rem 0px;
}

.container {
  // background: var(--color-black);
  // background: url("../../../public/assets/images/backgrounds/gradientBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 35px;
  padding-top: 1rem;
  @media (max-width:1200px){
    margin-bottom: -70px;
  }
}
.innerContainer {
  max-width: 1500px;
  margin: auto;
  background-color: var(--background-color4);
  padding: 20px 10px 20px;
  margin-top: -11px;
  @media (min-width: 1200px) {
    border-radius: 16px;
    padding: 40px 40px 40px;
    margin-top: 20px;
  }
}

.sect {
  gap: 2.2rem;
  padding: 4rem 1rem;
}

.loading {
  height: 100vh;
  background: var(--color-black);
}
