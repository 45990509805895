.rating {
  &__container {
    margin-bottom: 3em;

    h4 {
      @media (min-width: 1200px) {
        font-size: 1.5em;
      }
      padding: 2rem 0.5rem;
      margin: 0px;
      color: #fff;
      font-family: "Poppins-Medium";
    }

    h6 {
      padding: 8px;
      margin: 0px;
      font-size: 1em;
      color: var(--color-white);
      @media (max-width: 1200px) {
      }
    }
    &.ratingViewMode {
      svg {
        width: 16px;
      }
      h4,
      h6 {
        display: none;
      }
      margin-bottom: 0px;
      .rating__stars {
        padding-left: 0px;
      }
    }
  }

  &__stars {
    padding: 0.5em;
    cursor: pointer;

    .selected {
      svg,
      path {
        fill: #ccfd50 !important;
      }
    }
  }
}

@media screen and (max-width: 790px) {
  .rating {
    &__container {
      h6 {
        display: flex;
        justify-content: center;
        color: #ccfd51;
        margin-bottom: 1.2rem;
        margin: 0px;
      }
      h4 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0px;
      }
    }

    &__stars {
      display: flex;
      justify-content: flex-start;
      gap: 10px;

      svg {
        transform: scale(1.2);
      }
    }
  }
}
