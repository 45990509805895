@import "../../exdextypography.scss";

.container {
  // background: var(--color-black);
  font-size: 16px;
  max-width: 93vw;
  margin: auto;

  > * {
    @include responsive-font-size();
  }

  .topWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-color4);
    border-radius: 16px;
    padding: 0px 0px 10px 50px;
  }
  .bottomWrapper {
    background: var(--background-color4);
    border-radius: 16px;
    margin-top: 25px;
    padding-bottom: 30px;
  }
  .bottomSection {
    position: relative;
    padding-left: 100px;
  }
  .graphOuter {
    padding-top: 10px;
    padding-right: 10px;
  }

  .coinWrapper {
    display: flex;
    gap: 1.2em;

    .coinIcon {
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      img {
        border-radius: 100px;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }

    .coinDetails {
      .coinName {
        color: var(--color-white);
        font-size: 0.9em;
        margin-top: 0px;
        font-family: "Poppins-Medium";
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        span {
          font-family: "Poppins";
          color: var(--color-gray-font);
        }
      }
    }

    .coinShortName {
      font-size: 0.9em;
      color: #b9b9b9;
    }
  }

  .coinValue {
    color: var(--color-white);
    font-family: "Poppins";
    font-size: 38px;
    font-weight: 400;
    line-height: 39px;
    letter-spacing: 0.7020000219px;
    text-align: right;
    margin: 0px;
    margin-left: 12px;

    .percentage {
      color: var(--neo-lime);
      font-family: "Poppins";
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      text-align: right;
      margin-left: 20px;
    }
  }

  .danger {
    color: var(--color-orange) !important;
  }

  .webLink {
    margin-top: 20px;
    .link {
      text-decoration: none;
      color: #b9b9b9;
      cursor: pointer;
      background: rgba(89, 85, 85, 0.5098039216);
      border-radius: 30px;
      padding: 0.25em 0.7em;
      border: 2px solid transparent;
      margin: 0 0.25em;
      font-size: 14px;
      &:hover {
        border: 2px solid var(--color-green);
        color: var(--color-green);
      }
    }
  }

  .titleWrapper {
    font-size: 1.5em;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 0.5em;
    -webkit-line-clamp: 2;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .left {
    @media (min-width: 900px) {
      display: flex;
    }

    align-items: flex-start;
    position: relative;
  }

  .subTitles {
    color: #969696;
    font-size: 0.95em;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mainBanner {
    height: 30em;
    width: 100%;
    margin-bottom: 2em;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    img {
      height: 100%;
      width: auto;
      max-width: 100%;
      object-fit: cover;
      position: absolute;
    }
  }

  .description {
    color: #fff;
    word-break: break-word;
    font-size: 15px;
    line-height: 25px;
  }

  .sectionLeft {
    @media (min-width: 900px) {
      width: 60%;
    }

    padding: 2em 1em;
    gap: 1em;
  }

  .sectionHeading {
    color: #fff;
    font-size: 1.3em;
    margin-bottom: 1.1em;
    margin-top: 1em;
  }

  .latestProjects {
    margin-bottom: 3em;
  }

  .emainingNews {
    @media (min-width: 900px) {
      width: 50%;
    }

    margin: 0 auto;
  }

  .sliderGrp {
    width: 3em;
    position: absolute;
    height: 100%;
    padding-bottom: 10em;
    top: 7em;
    z-index: 1;
    left: 30px;
  }

  .goBack {
    color: var(--color-gray-font);
    font-size: 15px;
    padding: 0px;
    line-height: 38px;
    position: relative;
    z-index: 10;
  }

  .sectionRight {
    @media (min-width: 900px) {
      width: 40%;
    }

    padding: 1em;
  }

  .remainigPosts {
    display: none;
  }

  .remainingNews {
    @media (min-width: 900px) {
      width: 50%;

      .remainigPosts {
        display: block;
      }
    }

    padding: 1em;
    margin: 0 auto;
  }
}
.recom {
  font-size: 16px;
  font-weight: 400;
  line-height: 30.33px;
  letter-spacing: -0.453786700963974px;
  text-align: left;
  color: var(--color-white);
}

.bannerLoader {
  .bannerImageLoad {
    height: 30em;
    width: 100%;
    margin-bottom: 2em;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .textLines {
    height: 14px;
    margin: 20px 0px;
    &:nth-child(1) {
      width: 70%;
    }
    &:nth-child(2) {
      width: 50%;
    }
    &:nth-child(3) {
      width: 70%;
    }
    &:nth-child(4) {
      width: 80%;
    }
  }
}

.popularProjects {
  @media (max-width: 900px) {
    display: none;
  }
}

.scrollingDvc {
  position: relative;
  @media (min-width: 900px) {
    // padding-left: 3.5em;
    margin-bottom: 30px;
  }
}
.bgs {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // background: url("../../../public/assets/images/backgrounds/gradientBg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.calculatorWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  margin-right: 10px;
  margin-bottom: 40px;
  .numWrap {
    width: calc(50% - 10px);
  }
  @media (max-width: 900px) {
    margin-top: 10px;
    margin-right: 0px;
    margin-bottom: 40px;
    gap: 5px;
    .numWrap {
      width: calc(50% - 5px);
    }
  }
}
.priceVal {
  border: 1px solid var(--background-dark-blue);
  background-color: var(--light-green);
  border-radius: 15px;
  color: var(--btn-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0px 20px;
  font-family: "Poppins-Medium";
  height: 50px;
  margin-top: 5px;
  font-size: 17px;
  text-align: center;
  @media (max-width: 900px) {
    border-radius: 10px;
  }
}
@media only screen and (max-width: 1200px) {
  .container {
    .bottomSection {
      padding-left: 80px;
    }
    .topWrap {
      padding: 0px 0px 10px 30px;
    }
  }
  .recom {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1100px) {
  .coinWrapper {
    .coinIcon {
      width: 50px;
      height: 50px;
    }
    .coinValue {
      font-size: 32px;
      line-height: 27px;
      margin-left: 0px;
      span {
        display: block;
        text-align: left;
      }
      .percentage {
        margin: 0px;
        text-align: left;
        font-size: 15px;
        margin-top: 10px;
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .container {
    .topWrap {
      flex-direction: column;
      padding: 40px 10px;
      gap: 20px;
    }
    .graphOuter {
      padding: 0px;
    }
    .recom {
      text-align: center;
    }
    .sliderGrp {
      top: 7em;
    }
  }
}
@media only screen and (max-width: 750px) {
  .container {
    .sliderGrp {
      z-index: 1;
      left: 3px;
    }
    .topWrap {
      padding-top: 20px;
    }
    .bottomSection {
      padding-left: 35px;
    }
    .coinWrapper {
      display: block;
      .coinIcon {
        margin: 10px auto;
      }
    }
    .coinDetails {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

